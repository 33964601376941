.root {

}

.controls {
  margin: 10px 0 10px 0;
}

.controls span {
  margin-right: 5px;
}

.controls a {
  margin-right: 5px;
}

.actions {
  background-color: #efefef;
  border-radius: 0.4rem;
  display: inline-block;
  margin-left: 5px;
  padding: 5px;
}

.actions span {
  margin-right: 5px;
}