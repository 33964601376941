.root {

}

.small :global(.modal-container) {
    width: 400px;
}

.root :global(.modal-container) {
    padding: 0;
    max-height: none;
    box-shadow: none;
    border-radius: 0.4rem;
    border: 1px solid var(--border-color);
}

.root :global(.modal-container .modal-body) {
    padding: 0 1rem 1rem 1rem;
}

.root :global(.modal-container .modal-header) {
    padding: 1rem;
}
.root :global(.modal-footer) {
    padding: 1rem;
}

.root :global(.form-input-hint) {
    margin-bottom: 0.5rem;
}

.clear {
    float: right;
    padding: 0.5rem;
}