.project {
  font-size: 14px;
  border: 1px solid #efefef;
  padding: 0.4rem;
  margin: 5px;
  display: inline-block;
}

.stat {
  padding-right: 0.5rem;
}